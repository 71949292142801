'use client';
import Web3 from 'web3';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Icon } from '@chakra-ui/react';
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
//    Popover,
//    PopoverTrigger,
    useColorModeValue,
    useDisclosure,
    Image,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Logo from "../asset/vnbnode.png";
import blockchainExplorer from '../asset/blockchain-explorer.jpg';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import { useEffect, useState } from 'react';

export default function WithSubnavigation() {
    const { isOpen } = useDisclosure();
    const [connected, setConnected] = useState(false);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            popup: "modal_sweetalert2",
            confirmButton: "btn_confrim_sweetalert2",
            cancelButton: "btn_confrim_sweetalert2",
            input: "input_sweetalert2"
        }
    });

    useEffect(() => {
        if (window.ethereum && connected) {
            // Perform actions when MetaMask is connected
        }
    }, [connected]);

    async function connectMetaMask() {
        let web3;
        const chainIdDec = 10668;
        const chainData = {
            chainId: `0x${chainIdDec.toString(16)}`,
            chainName: 'VNBnode',
            nativeCurrency: {
                name: 'VNB',
                symbol: 'VNB',
                decimals: 18,
            },
            rpcUrls: ['https://evm-rpc.adam-vnbnode.site/'],
            blockExplorerUrls: ['https://explorer.vnbnode.xyz'],
        };

        if (connected) {
            swalWithBootstrapButtons.fire({
                title: "Enter your EVM wallet address",
                input: "text",
                showCancelButton: false,
                confirmButtonText: "Claim",
                preConfirm: async (tokenaddress) => {
                    try {
                        const githubUrl = 'https://3dym.vnbnode.xyz/claim';
                        const data = { userAddress: tokenaddress };

                        const response = await fetch(githubUrl, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(data),
                        });

                        if (!response.ok) {
                            const responseData = await response.json();
                            return Swal.showValidationMessage(`${responseData.details}`);
                        }
                    } catch (error) {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    toast("Successfully Faucet!");
                }
            });
        } else {
            if (window.ethereum) {
                web3 = new Web3(window.ethereum);
                try {
                    await window.ethereum.request({ method: 'eth_requestAccounts' });

                    const currentChainId = await web3.eth.getChainId();
                    if (currentChainId !== chainIdDec) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: chainData.chainId }],
                            });
                        } catch (switchError) {
                            if (switchError.code === 4902) {
                                await window.ethereum.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [chainData],
                                });
                            } else {
                                toast.error('Failed to switch chain');
                                return;
                            }
                        }
                    }
                    toast.success('MetaMask Connected');
                    setConnected(true);
                } catch (error) {
                    setConnected(false);
                    toast.error('User denied account access');
                }
            } else {
                setConnected(false);
                toast.error('MetaMask not detected');
            }
        }
    }

    return (
        <Box width={"100vw"}>
            <Flex bg={"white"} color={useColorModeValue('black', 'black')} py={6} px={4}>
                <Flex flex={{ base: 1 }} justify={{ base: "end", md: 'end' }}>
                    <Image width={"50px"} height={"50px"} src={Logo} alt="VNBnode Logo" />
                    <Box
                        alignItems={"center"}
                        display={{ base: 'none', md: 'flex' }}
                        justifyContent={"end"}
                        fontSize={{ md: '17px', xl: '20px' }}
                    >
                        <b>VNBnode provides services to operate Rollapps Sequencer</b>
                    </Box>
                </Flex>
                <Stack flex={{ base: 1, md: 1 }} justify={'end'} direction={'row'} spacing={6}>
                    {/* Blockchain Explorer */}
                    <Button color={"white"} backgroundColor={"gray.700"} as={'a'} href="https://explorer.vnbnode.xyz/">
                        <Image src={blockchainExplorer} height={"70%"} />
                    </Button>

                    {/* VNBNode Logo */}
                    <Button color={"white"} backgroundColor={"gray.700"} as={'a'} href="https://vnbnode.com/">
                        <Image src={Logo} height={"70%"} />
                    </Button>

                    {/* Social Media Icons */}
                    <IconButton
                        color={"white"}
                        backgroundColor={"gray.700"}
                        aria-label="Telegram"
                        fontSize="20px"
                        as={'a'}
                        href="https://t.me/VNBnodegroup"
                        icon={<FaTelegram />}
                    />
                    <IconButton
                        color={"white"}
                        backgroundColor={"gray.700"}
                        aria-label="Twitter"
                        fontSize="20px"
                        as={'a'}
                        href="https://x.com/vnbnode"
                        icon={<FaTwitter />}
                    />

                    {/* Connect/Faucet Button */}
                    <Toaster />
                    <Button
                        onClick={connectMetaMask}
                        id="connect"
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={connected ? 'gray' : '#2563eb'}
                        _hover={{ bg: '#2563eb' }}
                    >
                        {connected ? 'Faucet' : 'Connect MetaMask'}
                    </Button>
                </Stack>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const MobileNav = () => {
    return (
        <Stack bg={useColorModeValue('#020617', '#020617')} p={4} display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Box
                py={2}
                as="a"
                href={href ?? '#'}
                justifyContent="space-between"
                alignItems="center"
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Box>
        </Stack>
    );
};

const NAV_ITEMS = [];
